import './App.css';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, redirect } from 'react-router-dom'

function Upload() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const handleClick = () => {
    axios.post('https://i.petleshkov.com/image-upload', image)
    .then(res => {
      console.log('Axios response: ', res)
    })
  }

  const handlePaste = (e) => {

    const items = e.clipboardData?.items;

    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') === 0) {
          const file = items[i].getAsFile();

          // Now, upload the image file
          const formData = new FormData();
          formData.append('my-image-file', file);

          try {
            axios.post('https://i.petleshkov.com/image-upload', formData)
            .then(res => {
              window.open(`https://i.petleshkov.com/${res.data.files[0]}`)
            })
          } catch (error) {
            console.error('Error uploading image:', error.response.data);
          }
        }
      }
    }
  }

  const handleFileInput = (e) => {
    console.log('handleFileInput working!')
    console.log(e.target.files[0]);
    const formData = new FormData(); 
    formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
    setImage(formData);
  }
  return (
    <div className="App" onPaste={handlePaste}>
      <h1>Image Upload Tutorial</h1>
      <button onClick={handleClick}>Upload!</button>
      <input type="file" onChange={handleFileInput}/>
    </div>
  );
}

export default Upload;