import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';

export default function ShowImage() {
    const { imageId } = useParams()
    const [img, setImg] = useState()

    // const fetchImage = async () => { 
    //     console.log(imageId)
    //     const res = await fetch(`https://i.petleshkov.com/${imageId}`)
    //     console.log('res + ', res)
    //     const imageBlob = await res.blob();
    //     const imageObj = URL.createObjectURL(imageBlob);
    //     setImg(imageObj)
    // }

    // useEffect(() => {
    //     fetchImage()
    // }, [])

    return (
        <>
            <img src={`https://i.petleshkov.com/${imageId}`} />
        </>
    )
}